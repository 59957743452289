<template>
    <card>
        <loader/>
    </card>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'OAuth',
    async beforeRouteEnter (to, from, next) {
      if (to.params.provider && to.query) {
        const provider = to.params.provider
        const query = to.query

        try {
          const redirect = await store.dispatch('auth/authenticateSocialLogin', { provider, query })
          return next(redirect)

        } catch (e) {
          if (e.status === 200) {
            return next({ name: 'register', query: { email: e.response.email } })
          }

          return next({ name: 'login', query: { errors: 'oauth' } })
        }
      }
    }
  }
</script>
